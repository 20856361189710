import { useCallback, useEffect, useState } from "react";
import { Container} from "./styled";
import { Link } from "react-router-dom";
import { ContainerH, HeaderH, CardItemSacola, BorderLoja, Sacola, Pagamento, SacolaHeader, CardSacola, DivHidden} from "./styled";
import { FaTrash ,FaCartShopping, FaNoteSticky, FaWhatsapp, FaChevronLeft, FaChevronRight} from "react-icons/fa6";
import { config } from "../../db/config";

function Main() {
 
  const [produto, setProduto] = useState([]);
  const [sacola, setSacola] = useState([]);
  const [saldo, setSaldo] = useState(0.0)
  const [enviar, setEnviar] = useState('')
  const [controle, setcontrole] = useState(false);
  
      //Buscar  
      useEffect(()=>{
           const sacolaStorage =  localStorage.getItem('sacola');
           let saldo = 0.0;
           if(sacolaStorage){
            setSacola(JSON.parse(sacolaStorage));
            sacola.forEach(p => {
              parseFloat(saldo+=p.preco);
            });
            setSaldo(saldo);
           }      
      },[]);

      //salvar  
      useEffect(()=>{
        if(sacola.length>0){
          localStorage.setItem('sacola', JSON.stringify(sacola))   
        }               
      },[sacola, controle]);

      const handleDelete = useCallback((index)=>{
        const find = sacola.filter((p, i) => i !== index);
        if(sacola.length === 1){
          localStorage.removeItem('sacola')
        }
        setSacola(find);
      }, [sacola]);

      const handleDeleteAll = useCallback(()=>{
        setSacola([]);
        localStorage.removeItem('sacola') 
      }, [sacola]);

      let valorSacola = 0.0;

      function finalizarCompra(){

        let atring = '';
        let totalGeral = 0;
        atring += "%0A> *RESUMO DO PEDIDO*";
        sacola.map(p=>{
          atring += "%0A> " +p.nome.trim() + "%0A- *Qtd:* " + p.qtd + "  *Total:* R$ " + p.total;
          totalGeral += + p.total;
        })
        
        atring += "%0A> *Total do Pedido: R$" + totalGeral +"*";

        window.open('https://wa.me/'+config[0].whatsapp.numero+'?text='+atring, '_blank');
        setSacola([]);
        localStorage.removeItem('sacola')
        valorSacola = 0.0;
        atring = '';
      }


      

      useEffect(()=>{
        setcontrole(false)        
      },[controle]);



      function diminuirQtd(p, i){
        
        const novaQtd = sacola[i].qtd - 1 ;

        if(sacola[i].qtd > 1){
          sacola[i].qtd = novaQtd;
          sacola[i].total = sacola[i].qtd * sacola[i].preco

          setcontrole(true);
        }

      }

      

      

      function aumentarQtd(p, i){
        const novaQtd = sacola[i].qtd + 1 ;
        sacola[i].qtd = novaQtd;
        sacola[i].total = sacola[i].qtd * sacola[i].preco

        setcontrole(true);
      }


   return (
    <>


      <ContainerH>
        <HeaderH>
          
          <Link to={'/'}><img src={process.env.PUBLIC_URL+"/logo.png"} alt={config[0].loja.nome} /></Link>
          
        </HeaderH>
      </ContainerH>
      <BorderLoja/>
    <Container>
      <Sacola>
 
        <div>
        <SacolaHeader>
        <span><FaCartShopping size={22} color="#fb8817" /> PRODUTOS</span>
        { sacola.length > 0 ? ( <button onClick={()=>handleDeleteAll()}><FaTrash/> REMOVER TODOS OS PRODUTOS</button>) : '' }
        
        </SacolaHeader>
      {
        sacola.length > 0 ? (
          sacola.map((item, index) => (
            
            <CardItemSacola key={index}>
              <img src={Array.isArray(item.Image)? item.Image[0]:item.Image}/>
              <div>
                <em>{item.categoria[0]}</em>
                <h3>{item.nome}</h3>
                <p className="produto-descricao">{item.descricao}</p>
              </div>
                
              <div className="qtdAction">
                  <div className="qtd">
                    <label>Quant.</label>
                    <div className="alterQtd">
                      <button onClick={()=>diminuirQtd(item, index)}><FaChevronLeft/></button>
                      <input type="text" value={item.qtd} />
                      <button onClick={()=>aumentarQtd(item, index)}><FaChevronRight/></button>
                    </div>
                  </div>
                  <button onClick={()=>handleDelete(index)}><FaTrash/> <strong>REMOVER</strong></button>
              </div>
              <span>R$ {item.total.toFixed(2)}</span>
              
              <DivHidden> {parseFloat(valorSacola += item.total)} </DivHidden>
            </CardItemSacola>
  
          ))
        ) : (
          <CardSacola>
            <h2>Ops, parece que sua sacola está vazia. Que tal adicionar alguns itens?</h2>
          </CardSacola>
        )
      }
      </div>
      <Pagamento>
        <section>
          <div>
            <FaNoteSticky color="#fb8817" size={18}/> 
            RESUMO
          </div>
          <span>Valor dos produtos <em>R$ {valorSacola.toFixed(2)}</em></span>
        </section>
        <section>
          <button onClick={()=>finalizarCompra()}> <FaWhatsapp size={22}/> ENVIAR PEDIDO</button>
          <Link to={'/'}>CONTINUAR COMPRANDO</Link>
        </section>
      </Pagamento>
      </Sacola>
    </Container>
    
    </>
  );
}
export default Main;