

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #F2F3F4;

`;

export const Error = styled.div`
    max-width: 1280px;
    width: 1280px;
    height: 700px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;

    a{
        text-decoration: none;
        color: #FF7300;
        padding: 20px 40px;
        border-radius: 40px;
        border: 1px solid #FF7300;
        transition: all .3s;

    }
    a:hover{
        background: #FF7300;
        color: #fff;
    }

`;
