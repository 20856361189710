

import styled from "styled-components";

export const BorderLoja = styled.div`
    background: linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff);
    width: 100%;
    height: 10px;
`;
export const ContainerH = styled.div`
    max-width: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0,0,0, 0.2);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const HeaderH = styled.div`
    width: 1080px;
    max-width: 1080px;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    img{
        width: 50px;
    }

`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #F2F3F4;

`;

export const CardItemSacola = styled.div`
    max-width: 750px;
    width: 750px;
    border: 1px solid rgba(0,0,0,.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: #171717;
    position: relative;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;

    .qtdAction {
        width: 80px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .qtd{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3px;

            .alterQtd{
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: center;
                justify-content: center;
                
                button{
                    font-size: 22px;
                    width: 25px;
                    height: 25px;
                    color: #fb8817;
                    padding: 5px;
                    border-radius: 50%;
                }
            }

        }

        input{
            width: 40px;
            text-align: center;
            font-weight: 600;
            border: 0;
        }
        
        button{
        right: 160px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        outline: 0;
        color: rgb(213, 13, 13);
        background: transparent;
        border: 0;
        transition: all 0.2s;
    }

    
    }

    &:hover{
        box-shadow: 0 0 5px 2px rgba(0,0,0,.2);
    }

    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
    }
    div{
        width: 52%;
        h3{
            width: 90%;
        }
        p{
            width: 90%;
            text-align: justify;
            max-width: 100ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }       
    }
    
    button:hover{
        color: #BB0D0D;
    }
    span{
        position: absolute;
        right: 20px;
        font-size: 18px;
        font-weight:600;
        color: #fb8817;;
    }
`;
export const Sacola = styled.div`
    max-width: 1080px;
    width: 1080px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
    margin: 20px 0;    
`;

export const Pagamento = styled.div`
   width: 100%;
   height: 600px;
   padding: 0 15px;
   display: flex;
   flex-direction: column;
   gap: 20px;

   div{
    background: #fff;
    padding: 20px;
    display: flex;
    gap: 7px;
    font-size: 15px;
    font-weight: 600;
    color: #171717;
   }


   span{
    background: #fff;
    padding: 20px;
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    border-top: 1px solid #aaa;
    em{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
    }

   
   }
   section:nth-child(1){
    border-radius: 4px;
    overflow: hidden;
   }

   section:nth-child(2){
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    background: #fff;
    border-radius: 4px;

    button{
        padding: 10px 0;
        border-radius: 4px;
        border: 0;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        background: #fb8817;
        outline: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    a{
        padding: 10px 0;
        border-radius: 4px;
        border: 0;
        font-size: 15px;
        font-weight: 600;
        color: #fb8817;
        background: #fff;
        outline: 0;
        text-decoration: none;
        text-align: center;
    }
   }
   

`;

export const DivHidden = styled.div`
   display: none;
   background-color: aqua;
`;

export const CardSacola = styled.div`
   max-width: 750px;
   width: 750px;
   height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    color: #171717;
    position: relative;
    margin-bottom: 50px;
    border-radius: 4px;
`;

export const SacolaHeader = styled.div`
   width: 750px;
   max-width: 750px;
   height: 60px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-right: 20px;
   margin-bottom: 20px;
   background: #fff;
   border-radius: 4px;

   button{
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    background: transparent;
    border: 1px solid rgb(213, 13, 13);
    color: rgb(213, 13, 13);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
   }

   span{
    padding: 14px;
    font-size: 16px;
    font-weight: 600;
    color: #171717;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
   }

   

`;

