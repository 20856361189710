import styled from "styled-components";
export const Container = styled.div`
    position: relative;
    max-width: 100%;
    background: #ccc;
    box-shadow: 0 0 20px rgba(0,0,0, 0.2);
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #171717;


`;

export const Main = styled.div`
    width: 1280px;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;

    section:nth-child(1){
        width: 500px;
        max-width: 500px;
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            padding: 10px 0;
            width: 80px;
        }
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            padding: 20px;

            span:nth-child(2){
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 50px;
            }
        }
    }

    section:nth-child(2){
        margin-top: 20px;

        ul{
            margin-top: 10px;
            list-style: none;
        }

        a{
            color: #171717;
            opacity: .8;
            text-decoration: none;
            transition: all .3s;
        }
        a:hover{
            opacity: 1;
        }
    }

    section:nth-child(3){
        margin-top: 20px;
        ul{
            margin-top: 10px;
            display: flex;
            gap: 10px;
            list-style: none;
            
            a{
                color: #bd158e;
                opacity: .8;
                transition: all .3s;
            }
            a:hover{
                opacity: 1;
            }
        }
    }

    section:nth-child(4){
        width: 210px;
        margin-top: 20px;
        
        p{
            text-align: justify;
            margin-top: 10px;
        }
        ul{
            display: flex;
            gap: 10px;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;

            a{
                color: #FBF1F2;
                opacity: .8;
                background: #1DA851;
                font-size:20px ;
                padding: 4px 20px 4px 15px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                transition: all .3s;
                margin-top: 10px;

            }
            a:hover{
                opacity: 1;
            }
        }
    }

`;


export const Footer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #171717;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        width: 1280px;
        max-width: 1280px;
        color: #B4B4B4;
        a{
            color: #B4B4B4;
            text-decoration: none;
            cursor: pointer;
        }
            .textosFooter{
            text-aling:center;
            }
    }
`;

