import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from './pages/Main';
import Sacola from './pages/Sacola';
import Erro from './pages/Erro';

export default function Routess(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Main/>}/>
                <Route path='/buscar/:buscar' element={<Main/>}/>
                <Route path='/categoria/:categoria' element={<Main/>}/>
                <Route path='/categoria/:categoria/:id/:produto/' element={<Main/>}/>
                <Route path='/carrinho' element={<Sacola/>}/>
                <Route path='*' element={<Erro/>}/>
            </Routes>
        </BrowserRouter>
    )
};