import { useRef, useCallback, useEffect, useState } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import { Container, SpaceCarousel, CartIcon, ProdutoList, ProdutoCard, PageAction, DetalhesDoProduto } from "./styled";
import { Link, useParams, useHis } from "react-router-dom";
import { listaDeObjetos } from "../../db/estoque";
import { ContainerH, HeaderH, Form, SubmitButton, Sacola, BorderLoja } from "./styled";
import { FaMagnifyingGlass, FaCartShopping } from "react-icons/fa6";
import { listaDeObjetos as db } from "../../db/estoque";
import acessorios from "../../ui/img/acessorios-banner.png";
import sorteios from "../../ui/img/sorteio.png";
import resina from "../../ui/img/resina.png";
import eletronicos from "../../ui/img/eletronicos.jpg";
import oculus2 from "../../ui/img/oculos-2.webp";
import { config } from "../../db/config";

function Main() {

  const slideDB = [
    {
      "src": oculus2,
      "link": '/categoria/oculos'
    },
  ];

  const { categoria, id } = useParams();
  const [produtos, setProdutos] = useState([]);
  const protutosRef = useRef(produtos);
  const [categorias, setCategorias] = useState([]);
  const categoriaRef = useRef(categorias);
  const ListaProdutos = listaDeObjetos;
  const [sacola, setSacola] = useState([]);
  const [contador, setContador] = useState(20)
  const [slide, setSlide] = useState(0);
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [toFind, setToFind] = useState(false);
  const [upSacola, setUpSacola] = useState(false);
  

  const nextSlide = () => {
    setSlide(slide === produtos[id].Image.length - 1 ? 0 : slide + 1)
  }

  const prevSlide = () => {
    setSlide(slide === 0 ? produtos[id].Image.length - 1 : slide - 1)
  }


  const getDataCategoria = useCallback(() => {
    const categoriaList = [];
    ListaProdutos.forEach(produto => {
      produto.categoria.map(c => categoriaList.push(c));
    });

    const cat = [...new Set(categoriaList)];

    return cat;
  })

  useEffect(() => {
    categoriaRef.current = getDataCategoria();
    setCategorias(categoriaRef.current);
  }, [categoriaRef]);

  const getData = useCallback(() => {

    const produtosList = [];
    ListaProdutos.forEach(produto => {
      if (categoria) {

        if (produto.categoria.map(c => c.toLowerCase()).includes(categoria.toLowerCase())) {

          if (produtosList.length < contador) {
            produtosList.push(produto)
          }
        }
      } else {
        if (produtosList.length < contador) {
          produtosList.push(produto)
        }
      }
    });

    

    return produtosList;
  })




  useEffect(() => {
    protutosRef.current = getData();
    setProdutos(protutosRef.current);
    setToFind(true)
  }, [protutosRef]);




  useEffect(() => {
    const sacolaStorage = localStorage.getItem('sacola');

    if (sacolaStorage) {
      setSacola(JSON.parse(sacolaStorage));
    }
  }, []);


  useEffect(() => {
    if (sacola.length > 0) {
      localStorage.setItem('sacola', JSON.stringify(sacola))
    }
    if(upSacola)
    {
      setUpSacola(false);
    }

  }, [sacola, upSacola]);

  function handleiInputChange(produto) {
    
    const checkSacola = sacola.find((p, i )=> p.nome === produto.nome)

    if(checkSacola){
      sacola.find((p, i )=> p.nome === produto.nome ? (p.qtd += 1) : '')

      setUpSacola(true)

    } else {

    produto['qtd'] = 1;
    produto['total'] = produto.preco;
    setSacola([...sacola, produto]);

    }


  }

  useEffect(() => {
    setProdutos([]);
    protutosRef.current = getData();
    setProdutos(protutosRef.current);
  }, [protutosRef, categoria])

  function handleCategoria(categoria) {
    setContador(20);
  }


  const [buscar, setBuscar] = useState('');
  const [produtosEncontrados, setProdutosEncontrados] = useState([]);



  const handleSubmit =  (e) => {
    e.preventDefault();
    
    const result = [];
    db.forEach((p, i) => {
      if (p.nome.toLowerCase().includes(buscar.toLowerCase())) {
        result.push(p);
      }
    });
    setProdutosEncontrados(result);
    setBuscar('');
    setToFind(false);
  }

  useEffect(() => {

    if (produtosEncontrados.length > 0) {
      setProdutos([]);
      protutosRef.current = produtosEncontrados;
      setProdutos(protutosRef.current);
    }

  }, [protutosRef, produtosEncontrados])

  function handleInputChange(e) {

    setBuscar(e.target.value)
  }






  useEffect(() => {

    if (produtosEncontrados.length > 0) {
      setProdutos([]);
      protutosRef.current = produtosEncontrados;
      setProdutos(protutosRef.current);
    }

  }, [protutosRef, produtosEncontrados])


  useEffect(() => {


    protutosRef.current = getData();
    setProdutos(protutosRef.current);


  }, [contador])



  function handleLoadProdutos() {

    let newVar = contador + 20;
    setContador(newVar)

    getData();

  }


  const [slidebanner, setSlidebanner] = useState(0);

  const nextBunnerSlide = () => {
    setSlidebanner(slidebanner === slideDB.length - 1 ? 0 : slidebanner + 1)
  }

  const prevBunnerSlide = () => {
    setSlidebanner(slidebanner === 0 ? slideDB.length - 1 : slidebanner - 1)
  }

  const handleProduto = (prod, index) => {
    if(prod && index){
      localStorage.removeItem('produtoSelecionado');
      prod['id'] = index;    
      localStorage.setItem('produtoSelecionado', JSON.stringify(prod));
    }
  }



  useEffect(() => {

    const vp = localStorage.getItem('produtoSelecionado')

    if(vp)
    {
      setProdutoSelecionado(JSON.parse(vp));
    }
    
  }, [id])


  return (
    <>



    

      <ContainerH>
        <HeaderH>
          <Link to={'/'} ><img src={process.env.PUBLIC_URL + "/logo.png" } alt={config[0].loja.nome}  /> <h5>Entrega no mesmo dia em Uberlandia * consulte condições.</h5></Link>
            {/* <h4>Entregas no mesmo dia em Uberlandia*<br></br>consulte condições *</h4> */}
          <Form onSubmit={handleSubmit }>
            <input type="text" placeholder="Buscar produto"
              value={buscar}
              onChange={handleInputChange}
              />
            <SubmitButton>
              <FaMagnifyingGlass />
            </SubmitButton>
          </Form>
          <Sacola>
            <Link to={'/carrinho'}>
              <span>{sacola.length}</span>
              <FaCartShopping size={27} />
            </Link>
          </Sacola>
        </HeaderH>
      
      </ContainerH>
      <BorderLoja />
      <Container>
        <div className="Banners">
          <div className="ssdss">
            <div className="ssssd">
              {slideDB.length > 1 ? (<BsArrowLeftCircleFill className="bannerArrow bannerArrow-left" onClick={prevBunnerSlide} />) : ''}
              {
                slideDB.map((image, index) => {
                  return (<Link className="banner-link" key={index}  to={image.link}><img src={image.src} alt="" className={slidebanner === index ? 'slidebanner' : 'slidebanner slideslidebanner-hidden'} /></Link>)
                })
              }
              {slideDB.length > 1 ? (<BsArrowRightCircleFill className="bannerArrow bannerArrow-right" onClick={nextBunnerSlide} />) : ''}
              <span className="banner-indicators">
                {
                  slideDB.map((_, index) => {
                    return (<button key={index} onClick={null} className={slidebanner === index ? 'banner-indicator' : 'banner-indicator-inactive'}></button>)
                  })
                }
              </span>
            </div>
            <div className="sssds">
              <Link to={'/categoria/resina'}><img src={resina} /></Link>
              <Link to={'/categoria/eletronicos'}><img src={eletronicos} /></Link>
            </div>
          </div>

          <div className="sdsss">
            <Link to={'/categoria/sorteios'}><img src={sorteios} /></Link>
            <Link to={'/categoria/acessorios'}><img src={acessorios} /></Link>
          </div>
        </div>
      </Container>

      <Container>
        {id && toFind ? (

          handleProduto(produtos[id], id), 

          <DetalhesDoProduto>
            <div className="detalhesActions"><Link to={'/categoria/' + categoria} >Voltar</Link> | Categoria: <h3>{categoria[0].toUpperCase() + categoria.substring(1)}</h3></div>
            <div className="ddds">
              <div className="carousel">

                {Array.isArray(produtoSelecionado.Image) ? <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide} /> : ''}
                {Array.isArray(produtoSelecionado.Image) ?

                  produtoSelecionado.Image.map((image, index) => {
                    return (<img src={image} alt={produtoSelecionado.nome} key={index} className={slide === index ? 'slide' : 'slide slide-hidden'} />)
                  })

                  :
                  <img src={produtoSelecionado.Image} alt={produtoSelecionado.nome} className={'slide'} />
                }




                {Array.isArray(produtoSelecionado.Image) ? <BsArrowRightCircleFill className="arrow arrow-right" onClick={nextSlide} /> : ''}
                <span className="indicators">

                </span>

              </div>
              <div className="inforProduto">

                <div className="dddinfo">
                  <h3>{produtoSelecionado.nome}</h3>
                  <span>R$ {produtoSelecionado.preco}</span>
                </div>

                <div className="dddds">
                  <button onClick={() => handleiInputChange(produtoSelecionado)}><FaCartShopping size={22} /> ADICIONAR NO CARRINHO</button>
                </div>

              </div>

            </div>
            <div className="desc">
              <h2>Descrição:</h2><hr />
              <p></p>
              {produtoSelecionado.descricao}
            </div>
          </DetalhesDoProduto>











        ) : (
          <ProdutoList>
            {produtos.map((p, i) => (
              <ProdutoCard key={i}>
                <CartIcon onClick={() => { }}>

                </CartIcon>
                <Link onClick={()=>setToFind(true)} to={`/categoria/${p.categoria[0].toLowerCase()}/${i}/${encodeURIComponent(p.nome)}`}>
                  <div>
                    {Array.isArray(p.Image) ? <img src={p.Image[0]} alt={p.nome} /> : <img src={p.Image} alt={p.nome} />}


                    <p>{p.nome.substring(0, 80) + '...'}</p>
                    <span>R$ {p.preco}<em>À vista no PIX</em></span>
                  </div>
                </Link>
                <div>
                  <button onClick={() => handleiInputChange(p)}><FaCartShopping size={27} /> ADICIONAR NO CARRINHO</button>
                </div>
              </ProdutoCard>

            ))}
          </ProdutoList>
        )}
      </Container>
      {id ? '' : (
        <Container>


          <PageAction>
            <button type="button" onClick={() => handleLoadProdutos()}>Mais Produtos</button>
          </PageAction>



        </Container>
      )}
    </>
  );
}
export default Main;