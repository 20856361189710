

import styled, {keyframes, css} from "styled-components";
import acessorios from '../../ui/img/acessorios.png';
import oculos from '../../ui/img/oculos.png';
import ciclismo from '../../ui/img/ciclismo.png';
import utilidades from '../../ui/img/utilidades.png';
import eletronicos from '../../ui/img/eletronicos.png';


export const BorderLoja = styled.div`
  background: linear-gradient(to right, #5B51D8 20%, #C13584 100%, transparent 100%);

    width: 100%;
    height: 10px;
`;



export const ContainerH = styled.div`
    max-width: 100%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0,0,0, 0.2);
    height: 60px;
  background: linear-gradient(to right, #5B51D8 20%, #C13584 100%, transparent 100%);
      
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const HeaderH = styled.div`
    width: 1080px;
     max-width: 1080px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  opacity: 1;
          border-radius:20px;

    img{
        width: 50px;
        background: white;
        border-radius:20px;
    }
        h5{color: white;  }

`;




export const Form = styled.form`
    display: flex;

    flex-direction: row;

    input{
    
        border: 0;
        padding: 10px 15px;
        border-radius: 20px 0  0 20px;
        font-size: 14px;
        width: 100%;
        background: #ECECEC;
        color: #171717;      
    }

`;

export const SubmitButton = styled.button.attrs({
    
    type: 'submit'
})`

    border: 0;
    border-radius: 0 20px 20px 0;
    font-size: 14px;
    width: 80px;
    color: #FF7300;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ECECEC;
`;

export const Sacola = styled.span`
    position: relative;
    
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 20px;
    border: 0;
    
    a{
    
        text-decoration: none;
        position: relative;
        width: 45px;
        height: 45px;
        outline: 0;
        border: 0;
        color: #FF7300;
        span{
            
            position: relative;
            top: 11px;
            right: -16px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #FF7300;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            font-weight: 600;
            color: #FBF1F2;
        }
    }
    

`;










export const Container = styled.div`
    display: flex;
    
    flex-direction: column;
    align-items: center;
    width: 100%;
    

    .Banners{
    
        max-width: 1080px;
        width: 1080px;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        border-radius: 4px;
            background: linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff);

        .ssdss{
        
            display: flex;
            flex-direction: column;
            width: 800px;

            .ssssd{
                padding: 10px;
                display: flex;
                flex-direction: row;
                position: relative;
                
                align-items: center;
                justify-content: center;

                .banner-link{
                    img{
                    width: 800px;
                    height: 255px;
                    border-radius: 4px;
                    object-fit: cover;
                    
                    }
                }

                .slidebanner {
                    border-radius: 4px;
                    box-shadow: 0 0 5px #333;
                    width: 100%;
                    height: 100%;
                    
                }

                .bannerArrow {
                    position: absolute;
                    width: 2rem;
                    height: 2rem;
                    color: #171717;
                    filter: drop-shadow(0 0 2px #ccc);
                    
                }

                .bannerArrow:hover{
                    cursor: pointer;
                
                }

                .bannerArrow-left {
                    left: 1rem;
                    
                    
                }

                .bannerArrow-right {
                    right:  1rem;
                }

                .banner-indicators {
                    display: flex;
                    position: absolute;
                    bottom: 1rem;
                }

                .banner-indicator {
                    background-color: #fff;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 100%;
                    border: none;
                    outline: none;
                    margin: 0 0.2rem;
                    cursor: pointer;
                    box-shadow: 0 0 3px #171717;
                }

                .banner-indicator-inactive {
                    background-color: #333;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 100%;
                    border: none;
                    outline: none;
                    margin: 0 0.2rem;
                    cursor: pointer;
                }

               .slideslidebanner-hidden{
                display: none;
               }
            }

            .sssds{
                display: flex;
                flex-direction: row;
                gap: 20px;
                

                img{
                    width: 390px;
                    height: 155px;
                    border-radius: 4px;
                    box-shadow: 0 0 5px #333;
                }
            }
        }

        .sdsss {
            padding: 10px;
            display: flex;
            flex-direction: column;
            width:300px;
            gap: 10px;
            a:nth-child(1){
                img{
                width: 250px;
                height: 160px;
                border-radius: 4px;
                box-shadow: 0 0 5px #333;
            }
            }
            a:nth-child(2){
                img{
                width: 250px;
                height: 250px;
                border-radius: 4px;
                border: 2px solid #EDEDED;
                box-shadow: 0 0 5px #333;
            }
            }
        }

    }

`;

export const SpaceCarousel = styled.div`
    max-width: 1280px;
    width: 1280px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    
    padding: 20px 0;

    a{
        text-decoration: none;
    }

`;

export const ItemCategoria = styled.button`
    width: 120px;
    height: 120px;
    background: #833ab4;
    background: linear-gradient(200deg, #fb8817, #ff4b01, #c12127, #e02aff);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: 0;
    transition: all .3s;
    
    span{
        display: flex;
        width: 110px;
        height: 110px;
        background: #fff;
        background-size: 100%;
        border-radius: 50%;
        justify-content: center;
        align-items: end;
        padding-bottom:18px;
        font-weight: 600;
        color: #171717;
    }
    &.Acessorios span{
        background-image: url(${acessorios});
    }

    &.Oculos span{
        background-image: url(${oculos});
    }

    &.Ciclismo span{
        background-image: url(${ciclismo});
    }

    &.Utilidades span{
        background-image: url(${utilidades});
    }
    &.Eletronicos span{
        background-image: url(${eletronicos});
    }

    &:hover{
        background: linear-gradient(90deg, #fb8817, #ff4b01, #c12127, #e02aff);
        span{
            width: 109px;
            height: 109px;
        }
    }
`;

export const ListaProdutos = styled.div`
    max-width: 1280px;
    width: 1280px;
`;




export const PageAction = styled.div`
    max-width: 1280px;
    width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 20px 0;
    
    button{
        outline: 0;
        border: 0;
        background: #FF7300;
        color: #fff;
        font-weight: 600;
        padding: 10px 15px;
        border-radius: 4px;
    }
`;

export const ProdutoCard = styled.div`
    position: relative;
    width: 250px;
    min-width: 200px;
    height: 400px;
    padding: 10px;
    border: 1px solid rgba(0,0,0, .1);
    border-radius: 4px;
    box-sizing: border-box;
    transition: all .2s;
    margin-bottom: 20px;

    img{
        width: 228px;
        height: 228px;
        object-fit: cover;
    }

    p{
        font-size: 14px;
        font-weight: 600;
        color: #171717;
    }
    a{
        text-decoration: none;
    }
    span{
        display: flex;
        width: 100%;
        position: absolute;
        font-size: 28px;
        font-weight: 600;
        color: #FF7300;
        bottom: 55px;
        flex-direction: column;

        em{
            font-size: 12px;
            color: #171717;
            font-weight: 400;
            font-style: normal;
        }
    }

    div:nth-child(3){
        position: absolute;
        bottom: 10px;
        width: 92%;
        background: #171717;
        border-radius: 4px;
        overflow: hidden;

        button{
            background: #FF7300;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            outline: 0;
            border: 0;
            font-weight: 600;
            gap: 10px;
            color: #ffff;
            transition: all 0.2s;
        }

        button:hover{
            background: #B85605;
        }
        button:active {
            background-color: #EE7D20;
        }
    }

    &:hover{
        box-shadow: 0 0 5px 5px rgba(224,42,255, 0.2);
    }
`;


export const CartIcon = styled.button`
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    outline: 0;
    border: 0;
    border-radius: 50%;
    right: 10px;
    background: #FF7300;
    color: #fff;
    transition: all 0.2s;
    &:hover {
        background: #B85605;
    }
`;

export const ProdutoList = styled.div`
    max-width: 1080px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
`;

export const DetalhesDoProduto = styled.div`
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    
    .detalhesActions {
        display: flex;
        background: #fff;
        padding: 10px;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 4px;
        color: #171717;        

        a{
            border: 1px solid #FF7300;
            color: #FF7300;
            padding: 10px;
            border-radius: 4px;
            font-weight: 600;
            text-decoration: none;
            transition: all .2s;
        }
        a:hover{
            background: #FF7300;
            color: #fff;
        }
    }
    .ddds{
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        border-radius: 4px;

        .dddinfo {
        width: 400px;
        height: 350px;
        padding: 30px;
        font-size: 20px;
        background: #F2F3F4;
        border-radius: 4px;

        span{
            position: absolute;
            margin-top: 100px;
            padding: 30;
            font-size: 3rem;
            font-weight: 400;
        }
        }
    }

    
    .carousel {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        width: 700px;
        height: 700px;
        img{
            width: 100%;
            object-fit: fill;
        }        
    }
    
    .slide {
        border-radius: 4px;
        box-shadow: 0 0 7px rgba(0,0,0,0.3);
        width: 100%;
        height: 100%;
    }

    .slide-hidden{
        display: none;
    }

    .arrow {
        position: absolute;
        width: 2rem;
        height: 2rem;
        color: #FF7300;
        filter: drop-shadow(0 0 5px #000);
    }

    .arrow:hover{
        cursor: pointer;
    }

    .arrow-left {
        left: 1rem;
    }

    .arrow-right {
        right: 1rem;
    }

    .indicators{
        display: flex;
        position: absolute;
        bottom: 1rem;
    }

    .indicator {
        background-color: #fff;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 100%;
        border: none;
        outline: none;
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        margin: 0 .2rem;
        cursor: pointer;
    }

    .indicator-inactive{
        background: #aaa;
    }

    .inforProduto{
        display: flex;
        flex-direction: column;
    }

    .dddds{
        position: relative;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
        background: #F2F3F4;
        border-radius: 4px;


        button{
            border-radius: 4px;
            border: 0;
            outline: 0;
            background: #FF7300;
            color: #fff;
            font-weight: 600;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
        }
        button:hover {
            background: #B85605;
        }

        button:active {
            background-color: #EE7D20;
        }
    }

    .desc{
        padding: 40px 20px;
        font-size: 20px;
        text-align: justify;

    }

`;

