import { Link } from "react-router-dom";
import { Container, Error } from "./styled";

function Erro() {
    return (
      <Container>
        <Error>
        <h1>Hum, parece que essa página não existe!</h1>
        <Link to={'/'}>Voltar para página inicial</Link>
        </Error>
      </Container>
    );
  }
  
  export default Erro;