import { Container, Footer, Main } from "./styled";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube, FaWhatsapp } from "react-icons/fa6";
import { config } from "../../../db/config";

function Footers() {
  const whats = config[0].whatsapp.numero;
  const facebook = config[0].facebook.perfil;
  const instagram = config[0].instagram.perfil;
  const tiktok = config[0].tiktok.perfil;
  const youtube = config[0].youtube.canal;

  function newsletter(){ 
    window.open('https://wa.me/'+whats+'?text=Olá! Gostaria de me inscrever na newsletter para ficar por dentro das novidades. Poderiam me adicionar à lista de e-mails? Obrigado(a)!.', '_blank');
  }

  function atendimentoCliente(){ 
    window.open('https://wa.me/'+whats+'?text=Olá! Gostaria de acompanhar o status meu pedido!.', '_blank');
  }

    return (
      <Container>
        <Main>
        <section >
        <a href="/"><img src={process.env.PUBLIC_URL+"/logo.png"} alt="UDI Variedades" /></a>
          <p className="textosFooter">
           Pedidos em Uberlandia feitos ate as 17:00h <br>
           </br>  podem ser entregues no mesmo dia, consulte <br></br>  condições de acordo com seu bairro.
          </p>
        </section>
        <section></section>
       
        <section >
          <h3>Siga-nos no</h3>
          <ul>            
            <li><a href={"https://www.facebook.com/"+facebook} target='_blank'><FaFacebook size={50}/> </a></li>
            <li><a href={"https://www.instagram.com/"+instagram} target='_blank'><FaInstagram size={50}/> </a></li>
            {/* <li><a href={"https://www.tiktok.com/"+tiktok} target='_blank'><FaTiktok size={50}/> </a></li> */}
            <li><a href={"https://www.youtube.com/"+youtube} target='_blank'><FaYoutube size={50}/> </a></li>
          </ul>
        </section>
        <section >
          {/* <h3>Newsletter</h3>
          <p>Cadastre-se em nossa newsletter para receber informações atualizadas, notícias, insights ou promoções.</p>
          <ul>
            <li><a href="#" onClick={()=>newsletter()}><FaWhatsapp/><span>Whatsapp</span></a></li>
          </ul> */}
        </section>

        </Main>
        
        
        <Footer>
          <div>
            Copyright © 2024 Udi Variedades, Todos os direitos reservados. Desenvolvido por <a href="mailto:rafaelcruz.develope@gmail.com">Rafael Cruz</a>.
          </div>
        </Footer>
      </Container>
    );
  }
  
  export default Footers;