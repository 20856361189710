export const config = [
{
    whatsapp: {numero: '5534999729375'}, // Numero do Whatsapp aqui formato 5511995555555 
    facebook: {perfil: 'https://www.facebook.com'}, // Perfil do Facebook, somente o nome sem o @
    instagram: {perfil: ''}, // Perfil do Instagram, somente o nome sem o @
    youtube: {canal: ''}, // canal do Youtube
    tiktok: {perfil: ''}, // Perfil do Tiktok, com o @

    loja: {
        nome: 'UDI Variedades',
    }
}
];