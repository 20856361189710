import Routess from "./routes";
import Footer from "./ui/partials/Footers";
import GlobalStyle from "./ui/styles/global";


import { createContext } from "react";
export const ContextProvider = createContext();
function App() {
  return (
    <>
      <GlobalStyle/>
        <Routess/>
      <Footer/>      
    </>
  );
}

export default App;
